<template>
  <section class="relative">
    <img :src="`${IMG_ROOT}/arLink/webAR_linkPage_bg.jpg`" class="w-full" />
    <div class="wrapper">
      <router-link to="/index" class="block w-1/4 mx-auto">
        <img :src="`${IMG_ROOT}/webAR_logo.png`" class="w-full" />
      </router-link>
      <h1 class="title">
        This is Your own <br />
        AR Card Link!!
      </h1>
      <input
        type="text"
        class="input-link read-only"
        :value="link"
        @click="onClickInput"
        readonly
      />
      <div class="text-center">
        <button class="btn-yellow btn-copy" @click="copyLink">Copy Link</button>
      </div>
    </div>
    <Footer></Footer>
  </section>
</template>

<script>
import { inject, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router'
import data from '@/assets/arLink_data.json'
import Footer from '@/components/Footer.vue'

export default {
  name: 'ARLink',
  components: { Footer },
  beforeRouteEnter(to, from, next) {
    // Check params
    const cardId = to.query.card_id
    if (!cardId) return next('/')
    next()
  },
  setup() {
    const route = useRoute()
    const swal = inject('swal')

    // Set linkURL
    const AR_VIEW_PATH = getCurrentInstance().proxy.AR_VIEW_PATH
    const cardId = route.query.card_id
    const lineExternal = 'openExternalBrowser=1'
    const link = `${AR_VIEW_PATH}/?card_id=${cardId}&${lineExternal}`

    // Handel copy link action
    function onClickInput(e) {
      copyLink()
      e.target.blur()
    }

    async function copyLink() {
      const copyText = `${data.copyText}\nhttps://${link}`

      try {
        await navigator.clipboard.writeText(copyText)
      } catch (e) {
        const textArea = document.createElement('textarea')
        textArea.value = copyText
        textArea.style.position = 'fixed'
        textArea.style.top = '50%'
        textArea.style.left = '50%'
        textArea.style.transform = 'translate(-50%)'
        textArea.style.fontSize = '36px'
        textArea.style.opacity = 0

        document.body.appendChild(textArea)
        textArea.select()
        textArea.setSelectionRange(0, 99999)

        const isSuccess = document.execCommand('copy')
        document.body.removeChild(textArea)
        if (!isSuccess) return swal.fire('', 'Failed to copy', 'error')
      }

      swal.fire('', 'Link has been copied.', 'success')
    }

    return {
      link,
      onClickInput,
      copyLink
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin absolute-x-center {
  @apply absolute left-1/2 transform -translate-x-1/2;
}

.wrapper {
  width: 100%;
  padding-top: 33%;
  text-align: center;
  @include absolute-x-center();
  top: 0;

  .title {
    color: white;
    font-size: 3.6rem;
    line-height: 1.2;
    text-align: center;
    text-shadow: 5px 5px 10px rgb(0 0 0 / 0.6);
    margin: 5% 0;
  }

  input {
    width: 70%;
    font-size: 1.3rem;
    text-overflow: ellipsis;
    border-radius: 0.7rem;
    border: 2px solid black;
    margin-bottom: 15%;
    padding: 2% 3%;

    &:read-only {
      background-color: white;
    }
  }

  .btn-copy {
    width: 52%;
    font-size: 2.4rem;
    padding: 0.7rem 0;
    box-shadow: 2px 2px 10px 3px rgba(2, 35, 29, 0.6);
  }
}

footer {
  padding: 3% 0;
  text-align: center;
  background-color: #dc4726;
  color: white;
}
</style>
